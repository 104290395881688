
<template>

  <div>
    <div class="contents">
      <div class="uk-background-center-center parallax-bg" >
        <p class="desc">{{$_chlang(this.commonSetting.defaultDesc)}}</p>
        <h2 style="margin-top:0;" class="uk-text-center page-title uk-padding-small">
          Construction equipment<br>
          建機
        </h2>
        <div id="video-area">
          <video id="video" poster="@/assets/img/image/sub-links-jigyo-kenki.jpg" webkit-playsinline playsinline muted autoplay loop>
            <!--
                poster：動画ファイルが利用できない環境で代替表示される画像
                webkit-playsinline：iOS 9までのSafari用インライン再生指定
                playsinline：iOS 10以降のSafari用インライン再生指定
                muted：音声をミュートさせる
                autoplay：動画を自動再生させる
                loop：動画をループさせる
                controls：コントロールバーを表示する
            -->
          <source src="@/assets/img/image/Worlding-Kenki.mp4" type="video/mp4">
          <source src="@/assets/img/image/Worlding-Kenki.webm" type="video/webm">
          <p>動画を再生できる環境ではありません。</p>
          </video>
        </div>
        <top  />
      </div>
      <mini-manu />
    </div>
  </div>
  
</template>

<script>
import miniManu from'../modules/parts/MiniMenu.vue'
import top from'./kenki/Top.vue'

export default {
  name: 'JigyoKenki',
  components: {
    miniManu,
    top
  },
  data: () => ({
    activePage : '',
    pages: []
  }),

}
</script>

<style scoped lang="scss">
.uk-flex-center{
  *a{
    font-weight: 900;
    font-size: 1.2rem;
  }
}
/*========= 背景動画設定のCSS ===============*/
#video-area{
    position: fixed;
    z-index: -1;
    top: 0;
    right:0;
    left:0;
    bottom:0;
    overflow: hidden;
}

#video {
    /*天地中央配置*/
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    /*縦横幅指定*/
    width: 100%; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
    // width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
    // height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
    min-height: 100%;
    min-width: 100%;
    background-image:url(~@/assets/img/image/sub-links-jigyo-kenki.jpg);
}

.parallax-bg {
  text-align: center;
  padding-top: 40px;
}

.contents{
   background-color:rgba(0, 0, 0, 0.3);
}


</style>
