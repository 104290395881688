
<template>
  <div uk-scrollspy="cls: uk-animation-slide-bottom-small; target: div; delay: 200; repeat: true">
            
    <div class="message-line" v-for="(message, i) in message" :key="i"  >
      <span >{{$_chlang(message)}}</span>
    </div>
    <div class="btn-stocklist" >
      <a href="https://www.worldingsysdom.com/stock_info/list/kenki" target="_blank" >
        {{$_chlang(
          {ja:"在庫はこちら",en:"Stock List"}
        )}}
      </a>
    </div>
    
    <div v-if="$_isJapanese()" class="section-main uk-text-center" >
      <h3 class="heading-plan ">こんなお悩みはありませんか？</h3>
      <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match rental" uk-grid>
          <div>
              <span class="uk-card uk-card-default uk-card-body">
                  <p>建設機械がほしいけど、<br>新車は高くて手が届かない…</p>
              </span>
          </div>
          <div>
              <span class="uk-card uk-card-default uk-card-body">
                  <p>安く買いたいけど、<br>すぐに壊れないか不安…</p>
              </span>
          </div>
          <div>
              <span class="uk-card uk-card-default uk-card-body">
                  <p>品質・価格ともに<br>納得できる1台に出会いたい</p>
              </span>
          </div>
      </div>
    </div>
    
    <div class="section-title uk-text-left">
      <span >{{$_chlang({ja:"ご案内",en:"Guidance"})}}</span>
    </div>

    <div class="section-main uk-text-center" >

      <ul class="uk-subnav uk-subnav-pill" uk-switcher="animation: uk-animation-fade">
          <li  v-for="(page, i) in pages" :key="i" ><a href="#">{{$_chlang(page.title)}}</a></li>
      </ul>
      <ul class="uk-switcher uk-margin">
          <li><guide :flow="purchaseFlow" :question="purchaseQuestion" /></li>
          <li><guide :flow="salesFlow" :question="salesQuestion" /></li>
      </ul>

    </div>
   
  </div>
</template>

<script>
import jigyoKenki from '../../../mixins/jigyo-kenki.yaml'
import Guide from './Guide.vue'

export default {
  components: {
    Guide
  },
  data: () => ({
    message: [],
    pages:[],
    purchaseFlow: {},
    purchaseQuestion: {},
    salesFlow: {},
    salesQuestion: {}
  }),
  created() {
    this.message = jigyoKenki.constructionEquipmentMessage
    this.pages = jigyoKenki.kenkiMenuItem
    this.purchaseFlow = jigyoKenki.purchaseFlow
    this.purchaseQuestion = jigyoKenki.purchaseQuestion
    this.salesFlow = jigyoKenki.salesFlow
    this.salesQuestion = jigyoKenki.salesQuestion
  },
}
</script>

<style scoped lang="scss">
.message-line{
  span{
    white-space: pre-line;
    font-size: 1.1rem;
    color: rgb(255, 255, 255);
    font-weight: 400;
    text-shadow: 2px 2px 3px #000000,0px 0px 4px #000000,0px 0px 5px #000000;
  }
}
.btn-stocklist {
  border: none;
  background:var(--main-color);
  background: linear-gradient(0deg,var(--main-color) 0%, var(--main-color) 100%);
  color: #fff;
  overflow: hidden;

  padding :2vh 3vw;
  margin: 6vh 0;
  font-size : 2.5rem;
  border-radius: 15px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;

    a {
      color: #fff;
    }
    &:hover {
      text-decoration: none;
      color: #fff;
      opacity: .7;
    }
    &:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
      animation: shiny-btn1 3s ease-in-out infinite;
    }

    &:active{
      box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
                  -4px -4px 6px 0 rgba(116, 125, 136, .2), 
        inset -4px -4px 6px 0 rgba(255,255,255,.2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
    }
    @-webkit-keyframes shiny-btn1 {
        0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
        80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
        81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
        100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
    }
    @keyframes  shiny-btn1 {
        0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
        80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
        81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
        100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
    }
}
.heading-plan {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 4vh ;
  font-weight: 600;
  text-shadow: 1px 1px 1px #00000063;

    &::before,&::after {
      content: '';
      width: 4px;
      height: 40px;
      background-color: var(--main-color);
    }
    &::before {
      margin-right: 30px;
      transform: rotate(-35deg)
    }
    &::after {
      margin-left: 30px;
      transform: rotate(35deg)
    }
}

.section-title{
  background-color: rgba(77, 77, 77, 0.6);
}
.uk-subnav {
  li a {
    color: var(--main-color);
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.3rem 1.2rem;
  }
  .uk-active a{
    color: rgb(255, 255, 255);
    background-color: var(--main-color);
  }
}


</style>
