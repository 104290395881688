
<template>
  <div class="guidance">
    <h2 class="uk-heading-divider"><span>{{$_chlang(flow.title)}}</span></h2>
    <div class="uk-padding-small" v-for="(flowItem, i) in  flow.items" :key="i">
      <h3 class="heading-number" :data-number="'0'+(i+1)">{{$_chlang(flowItem.caption)}}</h3>
      <span >{{$_chlang(flowItem.detail)}}</span>
      <div v-if="flowItem.button" class="uk-padding-small">
        <a :href="flowItem.button.link" class="btn" target="_blank"><span>{{$_chlang(flowItem.button)}}</span></a>
      </div>
    </div>

    <h2 class="uk-heading-divider"><span>{{$_chlang(question.title)}}</span></h2>
    <div class="uk-padding-small" v-for="(questionItem, i) in  question.items" :key="'question'+i">
      <h3 class="heading-number" :data-number="'Q'+(i+1)">{{$_chlang(questionItem.caption)}}</h3>
      <span >{{$_chlang(questionItem.detail)}}</span>
      
    </div>
  </div>
</template>

<script>

export default {
  props: {
    flow: { type: Object },
    question: { type: Object },
  },  
}
</script>

<style scoped lang="scss">
.uk-heading-divider {
  font-weight: 600;
  border-bottom: calc(0.2px + 0.05em) solid  var(--main-color);
  text-shadow: 1px 1px 2px #000000 ;
  color: #ffffff;
}
.guidance{
  text-align: left;
  background: #ffffff4d;
  padding: 2vh 2vw;
}

.heading-number {
	position: relative;
  color: #ffffff;
  font-weight: 600;
  text-shadow: 1px 1px 2px #000000 ;

  &::before {
    content: attr(data-number);
    display: inline-block;
    margin-right: 20px;
    color: var(--main-color);
    font-size: 1.6rem;
    border-bottom: 2px solid  var(--main-color);
  }
}
span{
  white-space: pre-line;
}
</style>
